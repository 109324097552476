import ReactPlayer from 'react-player';
import React, { Component } from 'react';
import io from 'socket.io-client';
import {forwardRef } from 'react';

// const creativesPath = 'http://videoforge.loc/storage/creatives/'
const creativesPath = 'https://nikolskij.touchpanel.online/videos/'
const socketPath = 'http://194.183.174.157:6001'
// const socket = io(socketPath)

const socket = io("http://194.183.174.157:6001", {
    transports: ["websocket", "polling"],
    auth: {
        token: "abcd"
    }
});


class Player extends Component {
    constructor(props) {
        super(props);
        this.player1Ref = React.createRef();
        this.player2Ref = React.createRef();
        this.state = {
            url: creativesPath,
            playing: false,
            playerId: '11',
            socketUrl: socketPath,
            currentPlayer: "1",
            currentImage: "1",
            currentSlug: "",
            player: "",
            currentSrc: "",
            nextSrc: "",
            currentImageSrc: "",
            nextImageSrc: "",
            muted: true,
            currentType: "video",
        };
    }

    componentDidMount() {
        this.startPlayer();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.currentSlug !== prevState.currentSlug) {
            console.log('Slug changed:', this.state.currentSlug);
        }
        if (this.state.currentPlayer !== prevState.currentPlayer) {
            console.log('Player changed:', this.state.currentPlayer);
        }

    }

    startPlayer = () => {
        socket.on("connect", () => {
            console.log('Connected to server');
        });

        socket.io.on("error", (error) => {
            console.log(error);
        });

        socket.on("broadcast", (data) => {
            if (data) {
                data = JSON.parse(data);

                if (data.players[this.state.playerId]) {
                    this.setState({ player: data.players[this.state.playerId] });
                } else {
                    // console.log('Плеєр #' + this.state.playerId + ' відсутній, програється ' + Object.keys(data.players)[0]);
                    this.setState({ player: data.players[Object.keys(data.players)[0]] });
                }

                if (this.state.player && this.state.currentSlug !== this.state.player.current.slug) {
                    this.changePlayer();
                    this.seekPlayers();

                    this.setState({ currentSlug: this.state.player.current.slug });

                    if (this.state.player.current.type === 'video') {

                        if (this.state.currentSrc !== this.state.player.current.slug) {
                            this.setState({currentSrc: this.state.url + this.state.player.current.slug})
                            this.setState({nextSrc: this.state.url + this.state.player.next.slug})
                        }

                    } else if (this.state.player.current.type === 'image') {
                        // this.changeImage();
                    }
                }

            } else {
                console.log("Плейлист відсутній")
            }
        });

    }

    changePlayer = () => {
        this.setState({ currentPlayer: this.state.currentPlayer === "1" ? "2" : "1"  });
    };

    seekPlayers = () => {
        if (this.player1Ref.current && this.player2Ref.current) {
            this.player2Ref.current.seekTo(0);
            this.player1Ref.current.seekTo(0);
        }
    };

    getCurrentSrc = (id) => {
        if (this.state.player) {
            if (this.state.currentPlayer === id) {
                if (this.state.player.current.type === "video") {
                    return this.state.currentSrc;
                } else if (this.state.player.current.type === "image") {
                    return this.state.currentImageSrc;
                }
            } else {
                if (this.state.player.next && this.state.player.next.type === "video") {
                    return this.state.nextSrc;
                } else if (this.state.player.next && this.state.player.next.type === "image") {
                    return this.state.nextImageSrc;
                }
            }
        }
    }

    render() {
        return (
            <div className='player-wrapper'>
                <div className='react-player'>
                    <ReactPlayer
                        id="video_player1"
                        url={this.getCurrentSrc("1")}
                        width='100%'
                        height='100%'
                        playing={this.state.currentPlayer === "1" ? true : false}
                        controls={true}
                        preload="auto"
                        playsInline={true}
                        autoPlay={true}
                        ref={this.player1Ref}
                        style={{ display: this.state.currentPlayer === "1" ? 'block' : 'none' }}
                        muted={this.state.muted}
                    />
                    <ReactPlayer
                        id="video_player2"
                        url={this.getCurrentSrc("2")}
                        width='100%'
                        height='100%'
                        playing={this.state.currentPlayer === "2" ? true : false}
                        preload="auto"
                        controls={true}
                        playsInline={true}
                        autoPlay={true}
                        ref={this.player2Ref}
                        style={{ display: this.state.currentPlayer === "2" ? 'block' : 'none' }}
                        muted={this.state.muted}
                    />
                </div>
            </div>
        );
    }
}

export default Player;
